<template>
    <div>
        
        <div class="blog-area mt-5">
                <div v-if="!this.$store.state.recipe.loader" class="row">
                    <div style="margin-top:0px; margin-bottom: 20px;"  class="col-md-12">
                        <h4 class="text-center" style="font-family: Quicksand; font-size:30px; font-weight: 700;">Recipe </h4>
                    </div>
                </div>

                <div  style="min-height:73vh" class="row mt-4">
                    <div v-if="products.length<1" class="col-md-12"><h6  >
                        </h6>
                        <div class="col-md-1 mx-auto text-center">
                    <v-progress-circular
                    v-if="products.length<1"
                        class="mx-auto"
                        :size="90"
                        color="black"
                        indeterminate
                    ></v-progress-circular>
                </div>
                    </div>

                    <template v-for="item in products">
                        <div :key="item.id" class="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4 ml-5">
                            <div class="card-design">
                                <router-link class="readmore  " 
                                    :to="{name:'singleRecipe', params:{id:item.id}, query:{catId: item.category_id}}">  
                            <img class="img-fluid" style="object-fit: cover; display:block; margin:auto;height:200px; width: 300px" alt="" :src="item.thumbnail">
                            </router-link>
                            </div>
                            
                            <p class="text-center product-name mt-3">
                                <router-link class="readmore" 
                                    :to="{name:'singleRecipe', params:{id:item.id}, query:{catId: item.category_id}}">   
                                    {{ item.title}}
                                </router-link>
                            </p>
                            <div class="wizards" v-if="item.wizards.length > 0">
                                <span>Ingredients: </span>
                                <span v-for="(wizard,index) in item.wizards" :key="index">
                                    <span class="badge badge-pill badge-primary">{{ wizard.title }}</span>
                                </span>
                            </div>
                        </div>
                    </template>
                </div>
        </div>
        <notifications group="cart" position="top left" />
        <!-- snackbar -->
        <v-snackbar v-model="snackbar">
            {{ text }}
            <v-btn color="pink" text  @click="snackbar = false">
                Close
            </v-btn>
        </v-snackbar>
        <!-- snackbar -->
    </div>
</template>

<script>
//import Breadcrumb from '@/components/common/Breadcrumb'
    export default {
        name: "recipe",
        //components:{ Breadcrumb },
        data() {
            return {
            snackbar: false,
            text: '',
            searchKey: '',
            tempProducts: [],
            state: 0,
            products: [],
            articles:[],
            img:'https://s3.ap-south-1.amazonaws.com/german-butcher/'
            }
        },
         methods: {
            // getResults( page = 1 ){
            //     this.axios.get('/articles?page=' + page).then(response => {
            //         this.articles = response.data.articles;
            //         //console.log(response.data);
            //     });
            // },
            
            getProductByCategoryId(id) {
                this.axios.get(`/recipes?category_id=${id}`).then(response => {
                    this.products = response.data.data;
                    // console.log(this.products[0].name)
                });
            },
            cartAdded(product){
                this.productId=product.product_id;
                this.$store.commit('addTCart',product);
                this.snackbar = true,
                this.text = "Added to cart"
                localStorage.setItem('cart',JSON.stringify(this.$store.getters.cart_Info));
                // this.notify()
            },
            
            search(){
                //console.log(this.searchKey, '>>>>>>>')
                if(this.state == 0){
                    this.tempProducts = this.products
                    this.state = 1
                }
                var filteredItems = this.tempProducts.filter(item =>{
                    if(item.name.toLowerCase().match(this.searchKey.toLowerCase())){
                        return item;
                    }
                })
                this.products = filteredItems 
            },
            showAllproduct(){
                if(this.state == 1){
                    this.products = this.tempProducts
                    //this.state = 1
                }
            },
            filterBySub(categories_id){
                if(this.state == 0){
                    this.tempProducts = this.products
                    this.state = 1
                }
                var filteredItems = this.tempProducts.filter(item =>{
                    if(item.fk_categories_id == categories_id){
                        return item;
                    }
                })
                this.products = filteredItems
            },
            notify(){
            this.$notify({
                group: 'cart',
                type: 'error',
                title: 'Added to Cart',
            });
        }
        },//methods ends here
        filters: {
            limitCharacter(item) {
                item = item.replace(/<\/?[^>]+(>|$)/g, "");
                if (item.length > 30) {
                    return item.substring(0, 30) + ".......";
            } else {
                    return item;
                }
            }
        },//end of filter
        computed: {
            id: function(){
                return this.$route.params.id
            },
            breadCrumb: function () {
                return this.$route.query
            },
           type: function () {
                return this.$route.query.category
            },
        },
        watch: {
            // '$route.param.id'(newVal) {
            //     this.getProductByCategoryId(newVal)
            // }
            $route(){
                if(this.id)
                    this.getProductByCategoryId(this.id)
                }
        },
        mounted() {
            {
                this.getProductByCategoryId(this.id)
            }
           
        }
        
    };
</script>

<style scoped>
@font-face {
    font-family: 'Quicksand', sans-serif;
    src: url('/fonts/Quicksand-VariableFont_wght.ttf');
}

    .details {
        font-size: 14px;
        line-height: 18px;
    }
    .product-name a{
        font-weight: bold;
    }
    .product-name:hover a{
        color: #e54c2a;
    }
    .img-fluid{
        
 
 /* background: linear-gradient(312.02deg, #CF9333 0%, #EAC348 15%, #FBF1CA 46%, #DBA939 100%); */
 border-radius: 40px;
 padding: 7px;
 object-fit: fill;
  display:block;
   margin:auto;
  

    }
.card-design {
  margin: auto;
  width: 270px;
  height: 217px;
  border-radius: 40px;
  max-width: 324px; 
  max-height: 340px; 
  padding-top:1px; 
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 1px;
  border-left: 2px solid black;
  border-right: 2px solid black;
  border-top: 6px solid black;
  border-bottom: 7px solid black;
  }
</style>